<template>
<div>
    <div style="display: flex; justify-content: center; align-items: center; text-align:center;" class="intro-header" >
       <div  class="header-text">
            <div style="color: #333333; font-size: 32px; font-weight: bold;" class="header-title">
                服务中心
            </div>
            <div style="color: #999999;"> 
                Service Centre
            </div>
       </div>
    </div>
    <div class="content" style="width: 1200px;  margin: 0 auto;">
        <div style="margin-top: 40px; padding-bottom: 16px; border-bottom: 1px solid #EEEEEE; color: #3D3D3D; font-size: 24px;">
            新用户注册
        </div>
        <div style="margin-top: 44px; ">
            <div style="color: #FA8314; font-size: 18px; ">
                第一步
            </div>
            <div style="color: #333333; margin-top: 10px;">
                <div > 进入 <a :href="loginUrl" target="_blank">{{ loginUrl }}</a>          页面，点击页面右下角的"注册"链接。</div>
            </div>
            <div>
                <img style="margin-top: 10px; width: 100%; display: block;" src="@/assets/introduction/register-1.png"/>
            </div>
        </div>
        <div style="margin-top: 44px; ">
            <div style="color: #FA8314; font-size: 18px; ">
                第二步
            </div>
            <div style="color: #333333; margin-top: 10px;">
                在用户注册页面，在"手机号"输入框中输入手机号，点击"获取验证码"按钮，在"验证码"输入框中输入手机上接收到的验证码，按实际情况输入其他信息，点击"注册"按钮（必须选中"我已阅读并接受《科颐轩服务协议》"才能注册）。
            </div>
            <div>
                <img style="margin-top: 10px; width: 100%; display: block;" src="@/assets/introduction/register-2.png"/>
            </div>
        </div>
        <div style="margin-top: 44px; ">
            <div style="color: #FA8314; font-size: 18px; ">
                第三步
            </div>
            <div style="color: #333333; margin-top: 10px;">
                注册成功！
            </div>
            <div>
                <img style="margin-top: 10px; width: 100%; display: block;" src="@/assets/introduction/register-3.png"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import BGImage from '@/assets/ball-background.png';
import router from '../../../router';
export default {
    data() {
        return {
            BGImage
        }
    },
    computed: {
        loginUrl() {
            const href =  router.resolve({ name: 'login' });
            return `${window.location.origin}/${href.href}` ;
        }
    }
}
</script>

<style lang="less" scoped>
.intro-header {
    width: 100%;
    height: 250px;
    width: 100%;
    background: url(../../../assets/ball-background.png);
}
</style>